<!-- 停驶无忧 -->
<template>
  <div>
    <div class="rg_huwef">
      <img src="../../../image/banner_img.png" alt="" />
    </div>
    <div class="erg_hugw">
      <img src="../../../image/biao_content.png" alt="" />
    </div>
    <div class="erg_hwef">
      <div class="g_huwf">
        <div class="erg_hygwef">
          <img src="../../../image/zhongyaotongg_img.png" alt="" />
        </div>
        <div class="erg_hgyqwd">
          <p class="eg_hywd">1、保障期限1年，自该保障购买之日3日后生效；</p>
          <p class="eg_hywd">2、该保障绑定车辆的保险必须在有效期内；</p>
          <p class="eg_hywd">
            3、该保障收益人年龄需为18-60周岁，身体健康
            ，具备完全民事行为能力和完全生活自理能力的自然人；
          </p>
          <p class="eg_hywd">
            4、该保障受益人在保障其内无任何违法犯罪行为，且在保障生效日前一年内无酒驾，涉毒等行为；否则不能享受该保障
          </p>
          <p class="eg_hywd">5、该保障每个受益人限投一份，多投无效。</p>
        </div>
      </div>
      <div class="g_huwf">
        <div class="erg_hygwef">
          <img src="../../../image/butietianha.png" alt="" />
        </div>
        <div class="erg_hgyqwd">
          <p class="eg_hywd">补贴1天：车辆定损金额3000-4000金币(含)</p>
          <p class="eg_hywd">补贴2天：车辆定损金额4000-5000金币(含)</p>
          <p class="eg_hywd">补贴3天：车辆定损金额5000-6000金币(含)</p>
          <p class="eg_hywd">补贴4天：车辆定损金额6000-8000金币(含)</p>
          <p class="eg_hywd">补贴5天：车辆定损金额8000-10000金币(含)</p>
          <p class="eg_hywd">补贴6天：车辆定损金额10000-12000金币(含)</p>
          <p class="eg_hywd">补贴7天：车辆定损金额12000-14000金币(含)</p>
          <p class="eg_hywd">补贴9天：车辆定损金额18000-20000金币(含)</p>
          <p class="eg_hywd">补贴8天：车辆定损金额14000-18000金币(含)</p>
          <p class="eg_hywd">补贴10天：车辆定损金额20000金币以上</p>
        </div>
      </div>
      <div class="g_huwf">
        <div class="erg_hygwef">
          <img src="../../../image/fuwuliucheng.png" alt="" />
        </div>
        <div class="erg_hgyqwd">
          <p class="eg_hywd">
            1、登录车世际，在我的订单中查询停驶无忧或联系本公司在线客服查询该服务信息；
          </p>
          <p class="eg_hywd">2、该服务自购买之日起三个工作日后正式生效</p>
          <p class="eg_hywd">
            3、该服务项目发生后48小时在线申请相应补贴 ，且按提示提供资料，
          </p>
          <p class="eg_hywd">
            4、车世际平台收到补贴申请在48小时内审核完毕，审核完毕3个工作日内将相应补贴转账至受益人指定银行卡账户。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.rg_huwef {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 91px;
}
.erg_hugw {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 100px;
}
.erg_hwef {
  width: 100%;
  height: 678px;
  background: linear-gradient(180deg, #f7985c 0%, #f34a49 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.g_huwf {
  width: 450px;
  height: 353px;
  opacity: 1;
  border-radius: 0px 0px 24px 24px;
  margin-right: 45px;
}
.g_huwf:last-child {
  margin-right: 0;
}
.erg_hygwef {
  width: 450px;
  height: 85px;
  background: #f4634f;
  opacity: 1;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  padding-left: 31px;
}
.erg_hgyqwd {
  width: 450px;
  height: 353px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px 0px 24px 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 30px 0 30px;
}
.eg_hywd {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 28px;
  color: #333333;
}
</style>